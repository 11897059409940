import './App.css';
import supabase from './supabase';
import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

const HARD_LIMIT = 10;
const DEFAULT_COUNTERS = [1, 2, 3]; // Default counters to always display
const SLIDE_INTERVAL = 5000; // Duration for each slide

function App() {
  const [allQueue, setAllQueue] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0); // Track current slide for menu

  // Fetch data using the RPC function and group by counter_number
  const fetchCounters = async () => {
    const { data, error } = await supabase.rpc('get_token_and_counter_of_paid_orders_today'); // Calling the RPC
    if (error) {
      console.error('Error fetching data', error);
      return;
    }

    // Group tokens by counter_number
    const groupedCounters = data.reduce((acc, curr) => {
      if (!acc[curr.counter_number]) {
        acc[curr.counter_number] = [];
      }
      acc[curr.counter_number].push(curr.token);
      return acc;
    }, {});

    // Ensure that default counters (1, 2, 3) are always included, even if empty
    DEFAULT_COUNTERS.forEach((counter) => {
      if (!groupedCounters[counter]) {
        groupedCounters[counter] = []; // Empty array for counters with no tokens
      }
    });

    setAllQueue(groupedCounters); // Set the grouped counters to state
  };

  // Fetch menu items from Supabase
  const fetchMenuItems = async () => {
    const { data, error } = await supabase.from('menu').select('id, image, cost, name');
    if (error) {
      console.error('Error fetching menu items', error);
      return;
    }
    setMenuItems(data);
  };

  // Use Effect to fetch data once on load
  useEffect(() => {
    fetchCounters();
    fetchMenuItems();
    console.log("Fetched once");
  }, []);

  // Slide the menu items
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % Math.ceil(menuItems.length / 3)); // Show 3 items at a time
    }, SLIDE_INTERVAL);
    return () => clearInterval(interval);
  }, [menuItems]);

  const CounterComponent = (counter_number, tokens = []) => {
    return (
      <td
        className={`text-center text-white poppins max-h-screen w-1/5 ${
          counter_number !== Object.keys(allQueue).length ? 'border-r-2' : ''
        }`}
      >
        <div className="flex flex-row flex-wrap text-center items-center justify-center gap-10 p-5">
          {tokens.length > 0 ? (
            tokens.slice(0, HARD_LIMIT).map((token, index) => (
              <div key={index} className="text-7xl text-white poppins font-semibold">
                {token}
              </div>
            ))
          ) : (
            <div className="text-7xl text-white poppins font-semibold">
              No tokens
            </div>
          )}
        </div>
      </td>
    );
  };

  // Subscribe to changes using Supabase's channel
  supabase
    .channel('custom-update-channel')
    .on(
      'postgres_changes',
      { event: 'UPDATE', schema: 'public', table: 'orders' },
      (payload) => {
        console.log('Change received!', payload);
        fetchCounters(); // Re-fetch the data when changes occur
      }
    )
    .subscribe();

  return (
    <div className="App bg-[#a72a30] min-w-screen max-w-screen h-screen overflow-y-hidden pt-10">
      <div className="text-6xl text-white poppins font-semibold">
        MITS CANTEEN TOKEN SYSTEM
      </div>
      
      <div className="w-full h-[60%] flex flex-row ">
        <div className="w-1/4 h-full  rounded-lg p-2 flex flex-col items-center justify-center text-center">
                <p className="text-6xl text-white font-semibold mb-4 ">Scan to Order</p>
                <QRCode 
                  value="https://app.mitscanteen.com/"
                  bgColor="transparent"
                  fgColor="#fff"
                  size={400}
                  />
          </div>
        <table className="w-3/4 mt-5 border-collapse h-full">
          <thead>
            <tr>
              {DEFAULT_COUNTERS.map((counter_number, index) => (
                <th
                  key={index}
                  className={`text-5xl text-white poppins font-semibold text-center m-1 mt-10 border-b-2 ${
                    index !== DEFAULT_COUNTERS.length - 1 ? 'border-r-2' : ''
                  }`}
                >
                  Counter: {counter_number}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {Object.entries(allQueue).map(([counter_number, tokens], index) =>
                CounterComponent(counter_number, tokens)
              )}
            </tr>
          </tbody>
        </table>
      </div>

        {/* Menu Slideshow - Bottom 30% */}
      <div className="h-[40%] w-full -mt-16 p-2  flex items-center justify-center overflow-hidden">


        <div className="w-full flex flex-col overflow-hidden">
          <p className="text-6xl text-white font-semibold mb-4 text-left">Menu</p>

          {/* Show 3 items per slide */}
          <div className="flex gap-10 transition-transform duration-1000 ease-in-out" style={{ transform: `translateX(-${currentSlide * (100 / 5)}%)` }}>
            {menuItems.length > 0 ? (
              menuItems.map((item, index) => (
                <div key={item.id} className="flex flex-row min-w-fit items-center justify-center  border-2 rounded-xl h-40 overflow-hidden ">
                  <div className='flex flex-col pl-2'>
  
                    <h2 className="text-4xl font-bold text-white">{item.name}</h2>
                    <p className="text-3xl text-white font-semibold mt-2">₹{item.cost}</p>
                  </div>
                  <img 
                    src={item.image} 
                    alt={item.name} 
                    className="w-50 h-44 object-cover rounded-lg  mb-4" 
                  />
                </div>
              ))
            ) : (
              <p>No menu items available</p>
            )}
          </div>
        </div>
      </div>

      
    </div>
  );
}

export default App;
